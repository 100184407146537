import React from 'react';
import { Link } from "react-router-dom";

const SlickButton = (props) => {
  /*
  <div onClick={(event) => {event.preventDefault(); props.clickHandler(props.text);}} style={{position: "relative"}}>
      <a href="#" className="stretched-link"></a>
      <div>
        <p>
          {props.text}
        </p>
      </div>
      <div style={{height: "1px", backgroundColor: "black"}}>
      </div>
    </div>
  */
 if (typeof props.link !== "undefined") {
  return (
    <Link 
      to={props.link} 
      style={{textDecoration:"none", backgroundColor:"black"}}
    ><button className="button" role="button">{props.text}</button></Link>
  )
 } else if (typeof props.elink !== "undefined") {
  return (
    <a 
      href={props.elink} 
      target="_blank"
      style={{textDecoration:"none", backgroundColor:"black"}}
    ><button className="button" role="button">{props.text}</button></a>
  )
 } else {
   return (
     <button 
      className="button" 
      role="button" 
      onClick={(event) => {event.preventDefault(); props.clickHandler(props.text);}}
      style={{boxShadow:(props.active)?("#D6D6E7 0 3px 7px inset"):("")}}
      >{props.text}</button>
   )
 }
}

export default SlickButton;