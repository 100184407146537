import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useState, useEffect, useContext, useRef } from 'react';
import { DataContext } from '../../../contexts/DataContext';
import ConsultingItem from './items/ConsultingItem';
import ConsultingTimelineItem from './items/ConsultingTimelineItem';
import SlickButton from '../../general/SlickButton';


const ConsultingTimeline = () => {
    const data = useContext(DataContext);
    
    const PREVIEW = 2;
    const [displayItems, setDisplayItems] = useState(PREVIEW);
    const bottom = useRef()

    const expand = () => {
        if (displayItems == data.portfolio.consulting.length) {
            window.scrollBy({
                top: bottom.current.getBoundingClientRect().top,
                behavior: "instant"
            });
            setDisplayItems(PREVIEW);
            console.log()
        } else {
            setDisplayItems(data.portfolio.consulting.length);
        }
    }
    
    let posLeft = false;
    return (
        <div className='pageFBG' style={{width:"100%", position:"relative", paddingBottom:"10%"}}>
            <div style={{paddingLeft:"5%", paddingTop:"3%", paddingBottom:"3%", width:"60%"}}>
                <h4 style={{width:"60vw", marginBottom:"10px"}}>Consulting</h4>
                <p style={{marginBottom:"20px", fontSize:"1.8vh"}}>{data.general.headers.consulting}</p>
            </div>
            <div className="consultingTimelineBG" style={{overflowX:"hidden", overflowY:"hidden", paddingTop:"5%", paddingBottom:"5%"}}>
            <div ref={bottom}></div>
            {
                data.portfolio.consulting.map((item, index) => {
                    posLeft = !posLeft;
                    if (index < displayItems) {
                        return (
                            <ConsultingTimelineItem posLeft={posLeft} item={item} />
                        );
                    }
                })
            }
            </div>
            {
                (displayItems < data.portfolio.consulting.length) ? (
                    <div style={{width:"10%", minWidth:"100px", margin:"auto"}}>
                        <SlickButton text="More" clickHandler={expand} />
                    </div>
                    ) : (
                    <div style={{width:"10%", minWidth:"100px", margin:"auto"}}>
                        <SlickButton text="Less" clickHandler={expand} />
                    </div>
                )
            }
        </div>
    )
}

export default ConsultingTimeline