import React, {useContext, useEffect, useState} from 'react';
import {Row, Col} from 'react-bootstrap';
import { DataContext } from '../../contexts/DataContext';
import { useParams } from "react-router-dom";
import { Carousel } from 'react-bootstrap';

const OneProject = () => {
    const data = useContext(DataContext);
    const { id } = useParams();
    const [project, setProject] = useState();

    useEffect(() => {
        const projectList = data.portfolio.projects.filter(item => item.id == id);
        if (projectList.length == 0) {

        } else {
            setProject(projectList[0])
        }
    }, []);
    
    if (typeof project === 'undefined') {
        return (
            <div>Loading...</div>
        )
    } else {
        return (
            <div className='contentContainer' style={{height:"85vh"}}>
                <Row style={{height:"100%"}}>
                    <Col className="scroll-shadows" style={{overflowY:"scroll", overflowX:"hidden", height:"90%", marginTop:"2%", textAlign: "center"}}>
                        {
                            project.img.map((pic) => {
                                return (
                                    <img
                                        className='scrollImage'
                                        src={`${data.CONTENT}/pictures/portfolio/${pic}`}
                                    />
                                )
                            })
                        }
                    </Col>
                    <Col style={{height:"100%", width:"100%"}}>
                        <div className='oneProjectDetails' style={{height:"90%", paddingBottom:"15%"}}>
                            <div>
                                <h4>{project.title}</h4>
                            </div>
                            <div style={{float: "left"}}>
                                <p><i>{project.year} - </i></p>
                            </div>
                            <div className="hide-scrollbar" style={{height:"25px", overflowX:"scroll", display: "flex", marginBottom:"5%"}}>
                                <div style={{height:"20px", flex: "0 0 auto", margin:"2px", paddingRight:"5px", borderRight:"1px solid black"}}>
                                    {( typeof data.other.logos[project.mlang.toLowerCase()] === "undefined") ? (
                                            <p>{project.mlang}</p>
                                        ) : (
                                            <img style={{height:"100%"}} src={data.other.logos[project.mlang.toLowerCase()]} alt={project.mlang} />
                                        )}
                                </div>
                                {
                                    project.tech.map((tech, index) => 
                                    <div style={{height:"20px", flex: "0 0 auto", margin:""}}>
                                        {(typeof data.other.logos[tech.toLowerCase()] === "undefined") ? (
                                            <p>{tech}</p>
                                        ) : (
                                            <img style={{height:"100%"}} src={data.other.logos[tech.toLowerCase()]} alt={tech} />
                                        )}
                                    </div>
                                    )
                                }
                            </div>
                            <div className="scroll-shadows" style={{paddingBottom:"5%", overflowY:"scroll"}}>
                                {
                                    project.ldescription.split('\n').map(item => {
                                        return <p>{item}</p>;
                                    })                                
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default OneProject