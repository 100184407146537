import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";

const AcademicItem = (props) => {
    const [copied, setCopied] = useState(false);
    const [activation, setActivation] = useState(false);
    const changeHandler = (e) => {
        e.preventDefault();
        setActivation(prev => !prev)
    }

    const ref = useRef();
    const [scale, setScale] = useState(1);

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        listenScrollEvent();
        
        return () => {
          window.removeEventListener('scroll', listenScrollEvent);
        }
      }, []);

    const listenScrollEvent = e => {
        let top = ref.current.getBoundingClientRect().top;
        let anchor = top*1.2;
        let val = Math.abs(0.8+(1/(1+Math.log10(Math.abs(window.innerHeight/2 - anchor)))));

        if (val < 1.2) {
            setScale(String(val))
        } else {
            setScale("1.2")
        }
    }

    return (
        <div ref={ref} className='academicItem' style={{scale:scale, zIndex:(activation)?("5"):("0")}}>
            <a href="#" className="stretched-link" onClick={changeHandler}></a>
            <div style={{position:"relative"}}>
            </div>
            <div style={{display: "inline-block", verticalAlign:"middle", width:"7%"}}>
                <p>{props.item.date}</p>
            </div>
            <div style={{display: "inline-block", verticalAlign:"middle", width:"60%"}}>
                <h4 style={{paddingRight:"15px"}}>{props.item.title}</h4>
                <p><i>{props.item.authors}</i> ({props.item.type})</p>
            </div>
            <div style={{display: "inline-block", verticalAlign:"middle", width:"18%"}}>
                <p>{props.item.outlet}</p>
            </div>
            <div style={{display: "inline-block", verticalAlign:"middle", position:"relative", padding:"2px", zIndex:"2"}}>
                {
                    (typeof props.item.rg !== "undefined") ? (
                        <a href={props.item.rg} target="_blank"><img style={{width:"40px"}} src="https://upload.wikimedia.org/wikipedia/commons/5/5e/ResearchGate_icon_SVG.svg" className="stretched-link"/></a>
                    ) : (
                        <div></div>
                    )
                }
            </div>
            <div style={{display: "inline-block", verticalAlign:"middle", position:"relative", zIndex:"2"}}>
                {
                    (typeof props.item.pdf !== "undefined") ? (
                        <a href={`https://github.com/tkrpn/portfolio-content/raw/master/pdf/${props.item.pdf}`} target="_blank" download><img style={{width:"50px"}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCCAYAAADjVADoAAAABmJLR0QA/wD/AP+gvaeTAAADuklEQVR4nO3bz49eUxzH8ZfOVGeKqUZRIlQmLMQG00rahNJiI0IbusOCiNjwT3RTCU38jMQCCz8iQdtEqTGJriS6UVIWhHU7Q5AMTZ/H4jxDjee5P8+5t5Pcd/Ld3B/z/Xw/k/ucc773XDo6Ojo6OopyXgs5x7EJV+MSXDA4/gdO4Wf8iDNNimrCiEnsxJ3Yjhtwfs49f+EbzGEWR7CYUGNStuA1/IJ+zVjAq5hptIKa3IZP1S9+VBzGtsaqqcAVeAM96Uw4Ow7gqkYqK8FD4jwCVR6ZXQ3Ul8tqvKh5A5bHfmE0aoW1OJgjsMn4SBihGmUtvqgpPEXMadCM1TiUuKA6cUBDj8lLLRRXNvYnq37Ag+dAkUVjdyIPXIlfz4ECi8YCNqYw4u0EYj8Rps0z0sxG34ptwu0JRP6Gi87KMTU4FjNHT+Tp+JEERhwbkudYgjyHixS4qsA1t2JHkT9Wkl7BY3W5G5vzLipixOP1tbTOY3kX5BkxKeEw1CB75Mw484y4CxdHk9Me64QO2UjyjMi8eYVRy4jtEYV8iaeFZfvpjOtO4wU8g68i5r+j6o3j+FOcIey4/zZs78HRITmPDs4tsQbfRdKwiLEqRlwXSUAfzw35+9cXPBaz8TM9qtisR2NTxrmybPb/VwffD7lu+bFVQjc8FteOOpFlxFREAduwt8J9+8Rt4Veq6VHxp7v7SuTfmyD/IyU9QBojipqRwoQ+Hq5ixO5EYvLMSGVCHw9UMWJnQkF9w38znk2cs9LicTqxqL7QW5wSpsCvNJBv5KiR9TZ8DL9jIsutFcQiLjRiu0HW8HkG36ZQ1BLHZey5yFtrzMXV0iqfZ53MM2I2opC2qVXLBOal/xFLHaeEBdxI8l6NLeJdPJFz3TB6eB8/VLh3GNPCFoAi7cXlvCOspGtxi2r/hSfrJh7CUxV09HBTLAEfVxAQc9G2xLoKOg7GFLBV+S1BKTZ+bSmpoSfuMh68WVLECWGavgHra8YGoZF8oqSG12ObAJdbWSPISVyawgi4V3O75upED/cn8uAfnj8HCs2LMg2gyowJ84O2ix0VH6rYra7CpLAOabvo5fGZFlbLa/BeTeEx4wMtbC9cYlxorrRpQE/obDX2OGSxSztD60nc10B9pdiouU3pvUGuyxqprCJbVVubFI1Dwi6eFcOM8LHJgvrFz+Nl3JxKbBOfMk0IexN2CLvzbpTTJBF6B1/791OmWRH6CVm08XHbGK4RXjKvFzrLhI75PH4aRKMft3V0dHR0dBTnb41Ba9kcAJM9AAAAAElFTkSuQmCC" /></a>
                    ) : (
                        <div></div>
                    )
                }
            </div>
            <div style={{display: "inline-block", verticalAlign:"middle", width:"5%", height:"20%", position:"relative", padding:"2px", zIndex:"2"}}>
            {
                (typeof props.item.doi !== "undefined") ? (
                    <a href='' style={{textDecoration:"none"}}>
                    <div 
                        title={props.item.doi}
                        style={{backgroundColor:"black", borderRadius:"50%", color:"white", width:"40px", height:"40px", textAlign:"center"}} 
                        onClick={(e) => {e.preventDefault(); navigator.clipboard.writeText(props.item.doi); setCopied(true);}}

                    >
                        {
                            (copied) ? (
                                <p style={{paddingTop: "7px"}}>👍</p>
                            ) : (
                                <p className="doi"><strong>DOI</strong></p>
                            )
                        }
                    </div>
                    </a>
                ) : (
                    <div></div>
                )

            }
            </div>
            {(activation) ? (
                <div>
                    <hr style={{width:"auto", marginLeft:"10%", marginRight:"10%"}}></hr>
                    <div style={{display: "inline-block", margin:"2%", verticalAlign:"top", width:"20vh"}}>
                        {
                            (props.item.img != "") ? (
                                <img src={`${props.url}/pictures/portfolio/${props.item.img}`} alt={props.item.img} className="academicThumbnail"/>
                            ) : (<div></div>)
                        }
                    </div>
                    <div style={{display: "inline-block", margin:"2%", verticalAlign:"top", width:"70%"}}>
                        <p><i>Abstract</i></p>
                        {props.item.abstract}
                    </div>
                </div>
            ) : (<div></div>)}
            
        </div>
    )
}

export default AcademicItem;