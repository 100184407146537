import React, { useContext, useEffect } from 'react';
import {Row, Col} from 'react-bootstrap';
import { DataContext } from '../../contexts/DataContext';
import SlickButton from '../general/SlickButton';

const Contact = () => {
  const data = useContext(DataContext);
  
  return (
    <div style={{height:"40vh", paddingTop:"3%"}} className="pageFBG contentContainer">
        <Row style={{textAlign:"center"}}>
          <h2>Contact me!</h2>
        </Row>
        <Row style={{width:"90%", height:"100%", margin:"auto", textAlign:"center", marginTop:"2%"}}>
          {
            data.general.contact.map((item) => {
              return (
                <Col style={{width:"100%", height:"100%", marginLeft:"2%", marginRight:"2%"}}>
                  <div style={{width:"70%", height:"15%", margin:"auto", marginTop:"5%", marginBottom:"5%"}}>
                    <a href={item.link} target="_blank" style={{textDecoration:"none"}}>
                    {(typeof data.other.logos[item.title.toLowerCase()] === "undefined") ? (
                      <p style={{color:"black", width:"100%", fontSize:"5vh"}}>
                        <strong>{item.title}</strong>
                      </p>
                    ) : (
                      <img 
                      src={data.other.logos[item.title.toLowerCase()]} 
                      alt={item.img} 
                      style={{maxWidth:"100%", maxHeight:"100%"}}
                      />
                    )}
                    </a>
                  </div>
                  <div style={{height:"10vh"}}>
                    <p>{item.description}</p>
                  </div>
                  <div>
                    <a href={item.link} target="_blank" style={{textDecoration:"none", fontSize:"70%"}}>Go to {item.title}</a>
                  </div>
                </Col>
              );
            })
          }
        </Row>
    </div>
  )
}

export default Contact;