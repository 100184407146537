import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Navbar, Nav} from 'react-bootstrap';
import { Outlet, Link } from "react-router-dom";
import { BrowserView, MobileView } from 'react-device-detect';

const Navigation = () => {
  const [ mobileMenuExpand, setMobileMenuExpand]  = useState(false);

  return (
    <div>
      <BrowserView>
        <div style={{height:"5vh"}}>
        <Navbar className='pageFBG' variant='light' bg="light" style={{height:"100%"}}>
          <Container style={{height:"100%"}}>
            <Navbar.Brand><Link to="/" className="navLink">Tim Körppen</Link></Navbar.Brand>
            <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
            >
              <Link to="/" className="navLink">Home</Link>
              <Link to="/all-projects" className="navLink">Projects</Link>
              <Link to="/consulting" className="navLink">Consulting</Link>
              <Link to="/academia" className="navLink">Academia</Link>
              <Link to="/contact" className="navLink">Contact</Link>
            </Nav>
            <div style={{display: "flex", height:"100%", textAlign:"right"}}>
              <a href="https://www.linkedin.com/in/tim-körppen/" target="_blank" style={{height:"100%", width:"auto", padding:"2%"}}><img style={{height:"100%"}} src="https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg" alt="LinkedIn Icon" /></a>
              <a href="https://www.researchgate.net/profile/Tim-Koerppen" target="_blank" style={{height:"100%", width:"auto", padding:"2%"}}><img style={{height:"100%"}} src="https://upload.wikimedia.org/wikipedia/commons/5/5e/ResearchGate_icon_SVG.svg" alt="RG Icon" /></a>
              <a href="https://github.com/tkrpn" target="_blank" style={{height:"100%", width:"auto", padding:"2%"}}><img style={{height:"100%"}} src="https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg" alt="RG Icon" /></a>
            </div>
          </Container>
        </Navbar>
        </div>
      </BrowserView>
      <MobileView>
        <div style={{height:"5vh", position:"fixed", top:"0", width:"100%", zIndex:"1000"}}>
          <div style={{position:"relative"}}>
            <Navbar className='pageFBG' variant='light' bg="light" style={{height:"100%", position:"relative"}}>
              <Navbar.Brand><Link to="/" style={{marginLeft:"5%"}} className="navLink">Tim Körppen</Link></Navbar.Brand>
              <a href="" style={{position:"absolute", right:"5%"}} onClick={e => {e.preventDefault(); setMobileMenuExpand(prev => !prev)}}>
                {
                  (mobileMenuExpand) ? (
                    <i className="fa fa-times" style={{color: "black"}}></i>
                    ) : (
                    <i className="fa fa-bars" style={{color: "black"}}></i>
                  )
                }
              </a>
            </Navbar>
            {
              (mobileMenuExpand) ? (
                <div className='pageFBG' style={{padding:"2%", margin:"2%", boxShadow:"rgba(0, 0, 0, 0.4) 0px 2px 4px", borderRadius:"5px"}}>
                  <div>
                    <p>Visit on desktop for best experience!</p>
                  </div>
                  <hr></hr>
                  <div className='mobileMenu-Item'>
                    <Link to="/" className="navLink" onClick={() => setMobileMenuExpand(false)}>Home</Link>
                    <i className="fa fa-angle-right" style={{color: "black", position:"absolute", right:"10%"}}></i>
                  </div>
                  <div className='mobileMenu-Item'>
                    <Link to="/all-projects" className="navLink" onClick={() => setMobileMenuExpand(false)}>Projects</Link>
                    <i className="fa fa-angle-right" style={{color: "black", position:"absolute", right:"10%"}}></i>
                  </div>
                  <div className='mobileMenu-Item'>
                    <Link to="/consulting" className="navLink" onClick={() => setMobileMenuExpand(false)}>Consulting</Link>
                    <i className="fa fa-angle-right" style={{color: "black", position:"absolute", right:"10%"}}></i>
                  </div>
                  <div className='mobileMenu-Item'>
                    <Link to="/academia" className="navLink" onClick={() => setMobileMenuExpand(false)}>Academia</Link>
                    <i className="fa fa-angle-right" style={{color: "black", position:"absolute", right:"10%"}}></i>
                  </div>
                  <div className='mobileMenu-Item'>
                    <Link to="/contact" className="navLink" onClick={() => setMobileMenuExpand(false)}>Contact</Link>
                    <i className="fa fa-angle-right" style={{color: "black", position:"absolute", right:"10%"}}></i>
                  </div>
                  <hr></hr>
                  <div style={{textAlign:"center"}}>
                    <div style={{display: "inline-flex", height:"5vh"}}>
                      <a href="https://www.linkedin.com/in/tim-körppen/" target="_blank" style={{height:"100%", width:"auto", padding:"2%"}}><img style={{height:"100%"}} src="https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg" alt="LinkedIn Icon" /></a>
                      <a href="https://www.researchgate.net/profile/Tim-Koerppen" target="_blank" style={{height:"100%", width:"auto", padding:"2%"}}><img style={{height:"100%"}} src="https://upload.wikimedia.org/wikipedia/commons/5/5e/ResearchGate_icon_SVG.svg" alt="RG Icon" /></a>
                      <a href="https://github.com/tkrpn" target="_blank" style={{height:"100%", width:"auto", padding:"2%"}}><img style={{height:"100%"}} src="https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg" alt="RG Icon" /></a>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )
            }
          </div>
        </div>
        <div style={{height:"7vh"}} />
      </MobileView>
    </div>
  )
}

export default Navigation;