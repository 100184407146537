import './assets/css/App.css';
import { Container, Row, Col } from 'react-bootstrap';
import { Outlet, Link } from "react-router-dom";

import Navigation from './components/Navigation';
import { BrowserView, MobileView } from 'react-device-detect';
import { DataContextProvider } from './contexts/DataContext';

function App() {
  console.log(process.env.PUBLIC_URL);

  return (
    <DataContextProvider>
        <Navigation />
        <div className='pageBG'>
          <Outlet />
        </div>
        <div style={{height:"10vh", width:"100%", paddingTop:"2%"}} className="pageFBG contentContainer">
          <div style={{display:"inline-flex"}}>
            <Link to="/imprint" className="navLink">Imprint</Link>
          </div>
        </div>
      <MobileView>
        <p>Website is currently not available for mobile devices. Please visit the site with your desktop computer.</p>
      </MobileView>
    </DataContextProvider>
  );
}

export default App;
