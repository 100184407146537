import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { DataContext } from '../contexts/DataContext';
    
const Imprint = () => {
    const data = useContext(DataContext);

    return (
        <div style={{minHeight:"85vh"}}>
            <div style={{marginLeft:"10%", marginRight:"10%", width:"80%", paddingTop:"1%"}} className="oneProjectDetails">
                <h1>Imprint</h1>
                {
                    //givmeimpressumnow
                    (typeof data.other.imprint === "undefined") ? (<div>Loading...</div>) : (
                        data.other.imprint.split('\n').map(item => {
                            return <p>{item}</p>;
                        })
                    )                                
                }
                <a href="https://www.freepik.com/free-photo/elegant-white-background-with-blue-wave-lines_15829504.htm#query=elegant-white-background-with-blue-wave-lines&position=6&from_view=search&track=sph">Image by starline</a> on Freepik
            </div>
        </div>
    )
}

export default Imprint