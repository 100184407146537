import React, { useEffect, useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
//import ProfileBanner from './mainpage/ProfileBanner';
import ProfileBannerSlider from './mainpage/ProfileBannerSlider';
import Highlights from './mainpage/Highlights';
import Contact from './mainpage/Contact';

import Academia from './mainpage/highlights/Academia';
import ConsultingTimeline from './mainpage/highlights/ConsultingTimeline';

const Mainpage = (props) => {
    const cvRef = useRef()
    const consultingRef = useRef()
    const academiaRef = useRef()
    const contactRef = useRef()

    useEffect(() => {
        if (typeof props.view !== "undefined") {
            if (props.view == "Contact") {
                contactRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            } else if (props.view == "Cv") {
                cvRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            } else if (props.view == "Consulting") {
                consultingRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            } else if (props.view == "Academia") {
                academiaRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        }
    },[props.view])
//<Highlights view={props.view} />
    return (
        <div>
            <div ref={cvRef}>
                <ProfileBannerSlider/>
            </div>
            <div ref={consultingRef}>
                <ConsultingTimeline />
            </div>
            <div ref={academiaRef}>
                <Academia />
            </div>
            <div ref={contactRef}>
                <Contact />
            </div>
        </div>
    )
}

export default Mainpage;