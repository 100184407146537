import React, { createContext, useState, useEffect } from "react";
//import data from '../data/data.json';
const CONTENT = "https://raw.githubusercontent.com/tkrpn/portfolio-content/master";

export const DataContext = createContext();

export const DataContextProvider = (props) => {
    const [myData, setMyData] = useState();

    const [techList, setTechList] = useState([]);
    const [langList, setLangList] = useState([]);

    const getTechList = (data) => {
        let t = [];
        data.portfolio.projects.forEach(element => {
            t = [...t, ...element.tech.filter(telem => !t.includes(telem))];
        });
        setTechList(t);
    }

    const getLangList = (data) => {
        let t = [];
        data.portfolio.projects.forEach(element => {
            if (!t.includes(element.mlang)) {
                t.push(element.mlang);
            }
        });
        setLangList(t);
    }

    useEffect(() => {
        fetch(`${CONTENT}/data.json`)
        .then((response) => response.json())
        .then((data) => {
            setMyData(data);
            getTechList(data);
            getLangList(data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, []);

    return (
        <DataContext.Provider value={{...myData, techList, langList, CONTENT}}>
            {(typeof myData === "undefined") ? (
                <div className="lds-dual-ring"></div>
            ) : (
                <div>{props.children}</div>
            )}
        </DataContext.Provider>
    );
};