import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { useState, useEffect, useContext } from 'react';
import { DataContext } from '../../contexts/DataContext';

import SlidingProject from './highlights/items/SlidingProject';

import SlickButton from '../general/SlickButton';
import { Link } from "react-router-dom";
import { BrowserView, MobileView, isMobile, isBrowser } from 'react-device-detect';

const ProfileBannerSlider = () => {
    const data = useContext(DataContext);

    /*
    */
    return (
        <div style={{height: "120vh", width:"100%", overflowX:"hidden"}}>
            <Row style={{height: "100%"}}>
                <Col style={{position:"relative"}}>
                    {
                        (isBrowser) ? (
                            <div style={{height: "100%", width:"100%", overflow: "hidden", display: "inline-block", verticalAlign:"top", position:"relative"}}>
                                <div className='spinnerTimed'><div class="lds-dual-ring" style={{top:"30%", position:"relative"}}></div></div>
                                <Row style={{height: "100%", overflow:"hidden", position:"absolute", right:"-100px", top:"-40px"}}>
                                    <SlidingProject projects={data.portfolio.projects} url={data.CONTENT} class={"scollItemsToTop2"} />
                                    <SlidingProject projects={data.portfolio.projects} url={data.CONTENT} class={"scollItems"} />
                                    <SlidingProject projects={data.portfolio.projects} url={data.CONTENT} class={"scollItemsToTop"} />
                                </Row>
                            </div>
                        ) : (
                            <div></div>
                        )
                    }
                    <div className="contentContainer projectsSliderOverlay" style={{width:(isBrowser) ? ("40%") : ("100%")}}>
                        <div style={{textAlign:"center"}}>
                            <h2 style={{margin:"5%", scale:"1.5"}}><strong>Welcome to my site!</strong></h2>
                            <h4 style={{margin:"5%"}}>In the background you can see some of my projects. Click on them for more details or here to see them all!</h4>
                        </div>
                        <div style={{ margin: "auto", maxWidth: "50%"}}>
                            <SlickButton text="Projects" clickHandler={""} link="/all-projects" />
                        </div>
                        <div style={{textAlign:"center"}}>
                            <h4 style={{margin:"5%"}}>Please feel free to check out my past consulting projects or academic work as well.</h4>
                        </div>
                        <div style={{ margin: "auto", maxWidth: "50%", marginBottom:"2%"}}>
                            <SlickButton text="Consulting" clickHandler={""} link="/consulting" />
                        </div>
                        <div style={{ margin: "auto", maxWidth: "50%"}}>
                            <SlickButton text="Academia" clickHandler={""} link="/academia" />
                        </div>
                    </div>
                    <div style={{zIndex:"1", position:"absolute", maxWidth:"50%", height: "80vh", bottom:"15%", right:"-5%"}}>
                        <img className="jump" style={{height:"100%"}} src="/me.png" />
                    </div>
                    <div className="pageFBG-Profile" style={{zIndex:"200", position:"absolute", width:"100vw", height:"40%", bottom:"0%", paddingLeft:"2%", paddingRight:"2%"}}>
                        <Row style={{height: "40%"}}>
                            <Col style={{paddingTop:"2%", paddingLeft:"5%"}}>
                                <h4 style={{width:"70vw", marginBottom:"10px"}}>Facts & Figures</h4>
                                <p style={{width:"65vw", marginBottom:"20px", fontSize:"1.8vh"}}>{data.general.status}</p>
                            </Col>
                        </Row>
                        <Row style={{height: "60%", padding:"1%"}}>
                        {
                            data.general.facts.map((fact) => {
                                return (<Col className="threeDcard" style={{height:"70%", fontSize:"1.8vh"}}>
                                    {fact.split(/-*-/).map((s) => {
                                        if (s != "") {
                                            if (s.length > 4) {
                                                return (s)
                                            } else {
                                                return (<strong style={{fontSize:"200%"}}>{s}</strong>)
                                            }
                                        }
                                    })}
                                </Col>)
                            })
                        }
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ProfileBannerSlider;