import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useState, useEffect, useContext, useRef } from 'react';
import { DataContext } from '../../../contexts/DataContext';
import AcademicItem from './items/AcademicItem';
import SlickButton from '../../general/SlickButton';

const Academia = () => {
    const data = useContext(DataContext);
    const [publications, setPublications] = useState();

    const orderPublications = (p) => {
        p.sort((a, b) =>  {
            let barray = b.date.split("/");
            let aarray = a.date.split("/");

            return new Date(barray[1], barray[0]-1) - new Date(aarray[1], aarray[0]-1);
        });
        return(p);
    }
    
    useEffect(() => {
        let p = data.portfolio.publications.slice(0);
        setPublications(orderPublications(p));
    }, []);

    const PREVIEW = 4;
    const [displayItems, setDisplayItems] = useState(PREVIEW);
    const bottom = useRef()

    const expand = () => {
        if (displayItems == publications.length) {
            window.scrollBy({
                top: bottom.current.getBoundingClientRect().top,
                behavior: "instant"
            });
            setDisplayItems(PREVIEW);
            console.log()
        } else {
            setDisplayItems(publications.length);
        }
    }

    if (typeof publications === "undefined") {
        <div>Loading...</div>
    } else {
        return (
            <Col style={{position:"relative"}} className='contentContainer'>
                <div style={{paddingTop:"3%", paddingBottom:"3%", width:"60%"}}>
                    <h4 style={{width:"60vw", marginBottom:"10px"}}>Academia</h4>
                    <p style={{marginBottom:"20px", fontSize:"1.8vh"}}>{data.general.headers.academia}</p>
                </div>
                <div style={{margin: "auto", maxWidth: "90%", paddingTop:"5%", paddingLeft:"3%", paddingRight:"3%"}}>
                <div ref={bottom}></div>
                { 
                    publications.map((item, index) => {
                        if (index < displayItems) {
                            return (<AcademicItem item={item} url={data.CONTENT} />);
                        }
                    })
                }
                </div>
                {
                (displayItems < publications.length) ? (
                    <div style={{width:"10%", minWidth:"100px", margin:"auto", paddingBottom:"5%"}}>
                        <SlickButton text="More" clickHandler={expand} />
                    </div>
                    ) : (
                    <div style={{width:"10%", minWidth:"100px", margin:"auto", paddingBottom:"5%"}}>
                        <SlickButton text="Less" clickHandler={expand} />
                    </div>
                )
                }
            </Col>
        )
    }
}

export default Academia;