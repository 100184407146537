import React, {useContext, useState} from 'react';
import { DataContext } from '../../../contexts/DataContext';
    
const FilterLogo = (props) => {
    const data = useContext(DataContext);

    let img = typeof data.other.logos[props.tech.toLowerCase()] === "undefined"
    const [loaded, setLoaded] = useState(img);
    return (
        <div id={props.tech} style={{height:"3vh", width:"", margin:"2%", padding:"1.5%", position:"relative", display:"inline-flex", border: (props.active) ? (".5px solid grey") : (""), borderRadius: (props.active) ? ("5px") : ("")}}>
            {(img) ? (
                <p>{props.tech}</p>
                ) : (
                <img style={{height:"100%", display:(loaded) ? ("inline") : ("none")}} src={data.other.logos[props.tech.toLowerCase()]} alt={props.tech} onLoad={() => {setLoaded(true)}} />
            )}
            {(loaded)?(<div />):(
                <p>{props.tech}</p>
            )}
            <a href="" onClick={(e) => {e.preventDefault(); props.filterClickHandler(props.tech);}} className="stretched-link"></a>
        </div>
    )
}

export default FilterLogo;