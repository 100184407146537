import React, { useState, useContext, useRef, useEffect } from 'react';
import { Carousel, Container } from 'react-bootstrap';
import { DataContext } from '../../../contexts/DataContext';
import { Link } from "react-router-dom";

const ProjectItem = (props) => {
    const data = useContext(DataContext);

    const [techIsoOverflow, setTechIsOverflow] = useState(false);
    const [techContainerWidth, setTechContainerWidth] = useState(0);
    const techItemContainer = useRef();
    const techItemItems = useRef();

    useEffect(() => {
        setTechContainerWidth(techItemContainer.current?.offsetWidth);
        setTechIsOverflow(techItemContainer.current?.offsetWidth < techItemContainer.current?.scrollWidth);
        console.log(techItemContainer.current?.offsetWidth)
    }, [])


    return (
    <div className="projectCard" style={{position:"relative"}}>
        <div style={{width:"100%", height:"50%", position:"relative", zIndex:"100"}}>
            <Carousel variant="dark" interval={null} style={{height:"100%", width:"100%"}}>
                {
                    props.item.img.map((pic) => {
                        return (
                            <Carousel.Item>
                                <img
                                style={{maxHeight:"100%", maxWidth:"100%", objectFit:"contain", display: "block", marginLeft: "auto", marginRight: "auto"}}
                                src={`${data.CONTENT}/pictures/portfolio/${pic}`}
                                />
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
        </div>
        <div ref={techItemContainer} style={{overflowX:"hidden", position:"relative", width:"100%", height:"10%"}}>
            <div ref={techItemItems} className={(techIsoOverflow)?("vertscroll-animate"):("")} style={{display: "inline-flex", marginBottom:"5%", height:"50%", position:"absolute", zIndex:"100"}}>
                <div style={{height:"100%", width:"auto", flex: "0 0 auto", margin:"2px", paddingRight:"5px", borderRight:"1px solid black"}}>
                    {( typeof data.other.logos[props.item.mlang.toLowerCase()] === "undefined") ? (
                            <p>{props.item.mlang}</p>
                        ) : (
                            <img style={{height:"100%"}} src={data.other.logos[props.item.mlang.toLowerCase()]} alt={props.item.mlang} />
                        )}
                </div>
                {
                    props.item.tech.map((tech, index) => 
                    <div style={{height:"100%", width:"auto", flex: "0 0 auto", margin:"2px"}}>
                        {(typeof data.other.logos[tech.toLowerCase()] === "undefined") ? (
                            <p>{tech}</p>
                        ) : (
                            <img style={{height:"100%"}} src={data.other.logos[tech.toLowerCase()]} alt={tech} />
                        )}
                    </div>
                    )
                }
            </div>
        </div>
        <div>
            <h5>{props.item.title}</h5>
        </div>
        <div>
            <p className="cutText4"><i>{props.item.year}</i> - {props.item.ldescription}</p>
        </div>
        <Link to={`/project/${props.item.id}`} className="stretched-link" style={{textDecoration:"none"}}></Link>
        <style>
                {`
            .vertscroll-animate {
                animation: move 20s alternate ease infinite;
            }
          
            @keyframes move {
                0%   {
                    transform: translateX(0%);
                }
                100% { 
                    transform: translateX(calc(${techContainerWidth}px - 100%));
                }
            }
        `}
        </style>
    </div>
  )
}

export default ProjectItem