import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

//Quickfix safari
import { browserName } from 'react-device-detect';

const NUM_ITEMS = 7;

const SlidingProject = (props) => {
    const [projects, setProjects] = useState([]); 

    useEffect(() => {
        let shuffledProjects = [...Array(NUM_ITEMS).keys()].map(() => { 
            return props.projects[Math.floor(Math.random() * props.projects.length)];
        });
        setProjects(shuffledProjects);
    }, [])
    //browserName == "Safari"
    return (
    <Col style={{transform: "skew(15deg) rotate(-5deg)", overflowY:"hidden", height:"100%"}}>
        <div className={`${props.class}`}>
        { 
            projects.map((item, index) => {
                return (
                    <div className="slideImgContainer">
                        <img key={`${index}i`} className="slidingProjectImg" src={`${props.url}/pictures/portfolio/${item.img[Math.floor(Math.random() * item.img.length)]}`} />
                        <div className="slideImgOverlay" style={{paddingTop:"25%"}}>
                            <h4 key={index}>{item.title}</h4>
                            <p key={index}>{item.year}</p>
                            <p key={index} className="cutText4">{item.ldescription}</p>
                        </div>
                        <Link to={`/project/${item.id}`} className="stretched-link" style={{textDecoration:"none"}}></Link>
                    </div>
                );
            })

        }
        </div>
    </Col>
    )
}

export default SlidingProject;