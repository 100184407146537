import React, {useState, useEffect, useRef} from 'react';

const ConsultingTimelineItem = (props) => {
    const ref = useRef();
    const [inCenter, setCenter] = useState(false);
    const [left, setLeft] = useState((props.posLeft) ? ("0%") : ("50%"));
    const [opacity, setOpacity] = useState("0%");

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        
        return () => {
          window.removeEventListener('scroll', listenScrollEvent);
        }
    }, []);

    const listenScrollEvent = e => {
        let top = ref.current.getBoundingClientRect().top;
        let bottom = ref.current.getBoundingClientRect().bottom;
        let dist = Math.abs(window.innerHeight/2 - (bottom+(top-bottom)/2));
        if (dist < 100) {
            setCenter(true);
        } else {
            setCenter(false);
        }

        if ((bottom+(top-bottom)/2) > window.innerHeight/2) {
            if (props.posLeft) {
                setLeft(String(-1*dist/10)+"%");
            } else {
                setLeft(String(50+dist/10)+"%");
            }
            setOpacity(1/((dist/20)));
        } else {
            if (props.posLeft) {
                setLeft("0%");
            } else {
                setLeft("50%");
            }
            setOpacity("100%")
        }
    }

    return (
            <div ref={ref} className="dots" style={{marginBottom:"-5%", position:"relative", width:"50%", left:left, background: `linear-gradient(rgba(22,42,74, .5), rgba(22,42,74, .5)) no-repeat ${(props.posLeft) ? ("right") : ("left")}/15% 2px`, opacity:opacity}}>
                <div className="transition500ms" style={{backgroundColor:(inCenter) ? ("rgba(107, 177, 227, 1)"): ("rgba(22,42,74, 1)"), scale:(inCenter) ? ("2"): ("1"), border:"5px solid rgb(247, 250, 255)", borderRadius:"25px", top:"calc(50% - 25px/2)", left:(props.posLeft) ? ("calc(100% - 25px/2)") : ("-12.5px"), position:"absolute", height:"25px", width:"25px"}}></div>
                <div className="transition500ms" style={{margin:"auto", width:"60%", scale:(inCenter) ? ("1.05"): ("1"), textAlign:(props.posLeft) ? ("right") : ("left")}}>
                    <h4>{props.item.title}</h4>
                    <p style={{marginBottom:"20px", fontSize:"1.8vh"}}>{props.item.info.year} - {props.item.info.duration} - {props.item.info.role}</p>
                    <p style={{marginBottom:"20px", fontSize:"1.8vh"}}>{props.item.description}</p>
                    <p style={{marginBottom:"20px", fontSize:"1.8vh"}}><i>Client: {props.item.client.industry}, {props.item.client.revenue} revenue</i></p>
                </div>
            </div>
    )
}

export default ConsultingTimelineItem;