import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';
import Mainpage from './components/Mainpage';
import Imprint from './components/Imprint';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import AllProjects from './components/projectpage/AllProjects';
import OneProject from './components/projectpage/OneProject';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<App/>}>
          <Route path="" element={<Mainpage />} />
          <Route path="/projects" element={<Mainpage view={"Projects"} />} />
          <Route path="/consulting" element={<Mainpage view={"Consulting"} />} />
          <Route path="/academia" element={<Mainpage view={"Academia"} />} />
          <Route path="/cv" element={<Mainpage view={"Cv"} />} />
          <Route path="/contact" element={<Mainpage view={"Contact"} />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/all-projects" element={<AllProjects />} />
          <Route path="/project/:id" element={<OneProject />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
