import React, {useContext, useEffect, useState} from 'react';
import {Row, Col} from 'react-bootstrap';
import { DataContext } from '../../contexts/DataContext';
import FilterLogo from './items/FilterLogo';
import ProjectItem from './items/ProjectItem';

const AllProjects = () => {
    const data = useContext(DataContext);
    const [ projects, setProjects ] = useState();
    const [ techFilter, setTechFilter ] = useState([]);
    const [ langFilter, setLangFilter ] = useState([]);
    const [ yearFilter, setYearFilter ] = useState({from:"", to:""});
    const [ loading, setLoading ] = useState(true);

    const orderProjects = (p) => {
        p.sort((a, b) =>  new Date(b.year) - new Date(a.year));
        return(p);
    }
    
    useEffect(() => {
        let p = data.portfolio.projects.slice(0);
        setProjects(orderProjects(p));
    }, []);

    useEffect(() => {
        let filteredProjects = data.portfolio.projects.filter(item => {
            if (techFilter.length > 0) {
                if (techFilter.filter(value => item.tech.includes(value)).length > 0) {
                    return true;
                }
            } else {
                if (langFilter != "") {
                    if (langFilter == item.mlang) {
                        return true;
                    }
                } else {
                    return true;
                }
            }

            if (langFilter.includes(item.mlang)) {
                return true;
            }
        })
        setProjects(orderProjects(filteredProjects));
        
    }, [techFilter, langFilter, yearFilter]);

    const changeFilter = (filter, setFilter, value) => {
        if (filter.includes(value)) {
            let i = filter.indexOf(value);
            let filterCopy = filter.slice();
            filterCopy.splice(i, 1);
            setFilter(filterCopy);
        } else {
            setFilter([...filter, value]);
        }
    }
    
    const filterClickHandler = (value) => {
        //let value = event.target.value;
        if (false) {
            //console.log(event.target.value);
        } else if (data.langList.includes(value)) {
            changeFilter(langFilter, setLangFilter, value);
        } else if (data.techList.includes(value)) {
            changeFilter(techFilter, setTechFilter, value);
        }
    }

    if (typeof projects === "undefined") {
        return(<div className="lds-dual-ring"></div>);
    } else {
        return (
        <div className='contentContainer' style={{height:"85vh"}}>
            <Row style={{height:"100%", paddingTop:"2%", paddingBottom:"2%"}}>
                <Col xs={3} style={{height:"100%", borderRadius:"5px", padding:"2%", overflowY:"hidden"}} className="pageFBG">
                    <h5>Main language used</h5>
                    <div style={{backgroundColor:"", width:"100%"}}>
                        {
                            data.langList.map((tech, index) => 
                            <FilterLogo tech={tech} active={langFilter.includes(tech)} filterClickHandler={filterClickHandler} />
                            )
                        }
                    <a href="" onClick={(e) => {e.preventDefault(); setLangFilter([])}}>reset</a>
                    </div>
                    <hr />
                    <h5>Core technologies used</h5>
                    <div style={{backgroundColor:"", width:"100%"}}>
                        {
                            data.techList.map((tech, index) => 
                            <FilterLogo tech={tech} active={techFilter.includes(tech)} filterClickHandler={filterClickHandler} />
                            )
                        }
                    <a href="" onClick={(e) => {e.preventDefault(); setLoading(true); setTechFilter([])}}>reset</a>
                    </div>
                </Col>
                <Col style={{height:"100%"}}>
                    <div style={{height:"100%", overflowY:"scroll"}}>
                        {
                            projects.map((item, index) => {
                                return(<ProjectItem item={item} />)
                            })
                        }
                    </div>
                </Col>
            </Row>
        </div>
        )
    }
}

export default AllProjects